import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import get, { put } from "../../helper/api";
import { convertDateFormat } from "../../components/helper/DateConversion";
import Select from "react-select"

const EditSupplies = () => {
  let history = useHistory();
  const { id } = useParams();

  const [checked, setChecked] = useState(false);
  console.log("checked",checked);
  const [startDate, setStartDate] = useState(new Date());
  const [client, setClient] = useState([]);
  const [selectClient, setSelectClient] = useState();
  const [supplies, setSupplies] = useState({
    client_names: "",
    description: "",
    supplies_date: "",
    price: "",
    taxable: ""
  });


  const onInputChange = (e) => {
    setSupplies({ ...supplies, [e.target.name]: e.target.value });
  };

  const handleChange = () => { 
    setChecked(!checked); 
  };

  useEffect(() => {
    loadPortfolio();
    clients();
  }, []);

  const clients = async () => {
    const res = await get("client/getClientDropdown")
    const data = res.data.Items
    console.log("Another Data", res.data.Items)
    const options = data.map(d => ({
      value : d.id,
      label : d.client_names
    }))
    setClient(options)
 };
 
  const onSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      client_names: selectClient.label,
      description: supplies.description,
      supplies_date: convertDateFormat(startDate),
      price: supplies.price,
      taxable: checked
    }
    put(`supplies/updateSupplies?id=${id}`, payload).then(res => {
      history.push("/admin/supplies")
    });
    loadPortfolio();
    history.push("supplies");
  };

  const loadPortfolio = () => {
    get(`supplies/getSupplie?id=${id}`)
      .then((res) => {
        var data = res.data.data.Item;
        console.log("taxable", data.taxable);
        console.log("date supplies", data.supplies_date);
        console.log("select client", data.client_names);
        setSupplies(data);
        setSelectClient({label:data.client_names,value:data.client_names})
        setChecked(data.taxable)
        setStartDate(new Date(data.supplies_date))
      })
      .catch(() => {});
  };
  const { client_names, description, supplies_date, price, taxable } = supplies;

  return (
    <div className="container">
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Edit A Portfolio</h2>
        <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
            <Select
            
              // defaultValue={selectClient}
              value={selectClient}
              onChange={setSelectClient}
              options={client}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your description"
              name="description"
              value={description}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="form-group">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your  price"
              name="price"
              value={price}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <input type="checkbox" name="taxable" value={taxable} onChange={handleChange} checked={ checked}/>
          <button className="btn btn-warning btn-block">
            Update Supplies
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditSupplies;
