import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import get, { post } from "../../helper/api";
import { convertDateFormat } from "../../components/helper/DateConversion";
import Select from "react-select"

export default function AddSupplies() {
  let history = useHistory();
  const [checked, setChecked] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [client, setClient] = useState([]);
  const [selectClient, setSelectClient] = useState();
  const [supplies, setSupplies] = useState({
    client_names: "",
    description: "",
    supplies_date: "",
    price: "",
    taxable: ""
  });

  const { client_names, description, supplies_date, price, taxable } = supplies;
  const onInputChange = (e) => {
    setSupplies({ ...supplies, [e.target.name]: e.target.value });
  };

  const handleChange = () => { 
    setChecked(!checked); 
  };

  useEffect(() => {
    clients();
  }, []);

  const clients = async () => {
    const res = await get("client/getClientDropdown")
    const data = res.data.Items
    console.log("Another Data", res.data?.Items)
    const options = data.map(d => ({
      value : d.id,
      label : d.client_names
    }))
    setClient(options)
 };
 
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      let payload = {
        client_names: selectClient.label,
        description: supplies.description,
        supplies_date: convertDateFormat(startDate),
        price: supplies.price,
        taxable: checked
      }
      const res = await post("supplies/createSupplies", payload)
      setSupplies(res.data);
      history.push("/admin/supplies");
    } catch (err) {
      console.log(err);
      history.push("/admin/supplies");
    }
    // console.log("payload", payload);
  };

  return (
    <div className="container">
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Add A Supplies</h2>
        <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
            <Select
              defaultValue={selectClient}
              onChange={setSelectClient}
              options={client}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your description"
              name="description"
              value={description}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="form-group">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your  price"
              name="price"
              value={price}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <input type="checkbox" name="taxable" value={taxable} onChange={ handleChange}/>
          <button type='submit' className="btn btn-primary btn-block">Add Supplies</button>
        </form>
      </div>
    </div>
  );
}
