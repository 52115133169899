import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { post } from "../../helper/api"

export default function AddClient () {
  let history = useHistory();
  const [client, setClient] = useState({
    client_names: "",
    rate: "",
    tax_name:""
    
  });

  const { client_names, rate,tax_name } = client;
  const onInputChange = e => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

 
    
  const onSubmit = async e => {
    e.preventDefault()
    let payload = {
      client_names: client.client_names,
      rate: client.rate,
      tax_name: client.tax_name
    }
    try {
      const res = await post("client/createClient", payload)
      console.log("Res", res);
      setClient(res.data);
      history.push("/admin/clients")
    } catch (err) {
      console.log(err);
      history.push("/admin/clients")
    }
  }
  
  return (
    <div className="container">
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Add A client</h2>
        <form onSubmit={e => onSubmit(e)}>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Client Name"
              name="client_names"
              value={client_names}
              onChange={e => onInputChange(e)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Rate"
              name="rate"
              value={rate}
              onChange={e => onInputChange(e)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Tax Name"
              name="tax_name"
              value={tax_name}
              onChange={e => onInputChange(e)}
            />
          </div>
          
        
          <button type='submit' className="btn btn-primary btn-block">Add Client</button>
        </form>
      </div>
    </div>
  );
}

