import moment from "moment";

export function convertDateFormat(date) {
  return moment(date).format("L");
}

export function convertTimeFormat(time) {
    return moment(time).format("hh:mm A");
}

export function convertDatePickerToDateTime(date) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

export function convertMilliToDate(date) {
  return moment(date).format("DD/MM/YYYY");
}