import DatePicker from "react-datepicker";
import { convertDateFormat, convertTimeFormat } from "../../components/helper/DateConversion";
import React, { useState, useEffect } from "react";
import {  Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import get, { put } from "../../helper/api"
import Select from "react-select"

const EditTimeEntries = () => {
  let history = useHistory();
  const [checked, setChecked] = useState(false); 
  const [startDate, setStartDate] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [fromTime, setFromTime] = useState(new Date());
  console.log("from time", fromTime);
  const [client, setClient] = useState([]);
  const [selectClient, setSelectClient] = useState();
  const { id } = useParams();
  const [timeEntries, setTimeEntries] = useState({
    client_names: "",
    job_description: "",
    timeEntries_date: "",
    from_time: "",
    to_time: "",
    taxable: ""
  });

  const { client_names, job_description, timeEntries_date, from_time, to_time, taxable} = timeEntries;

const [loading,isLoading]=useState(false)
  
const onInputChange = e => {
  setTimeEntries({ ...timeEntries, [e.target.name]: e.target.value });
  };
  
  const handleChange = () => { 
    setChecked(!checked); 
  }; 

  useEffect(() => {
    loadPortfolio();
    clients();
  }, []);

  const clients = async () => {
    const res = await get("client/getClientDropdown")
    const data = res.data.Items
    const options = data.map(d => ({
      value : d.id,
      label : d.client_names
    }))
    setClient(options)
 };

  const onSubmit = async e => {
    e.preventDefault();
    let payload = {
      client_names: selectClient.label,
      job_description: timeEntries.job_description,
      timeEntries_date: convertDateFormat(startDate),
      from_time: convertTimeFormat(fromTime),
      to_time: convertTimeFormat(toTime),
      taxable: checked
    }
    put(`timeEntries/updatetimeEntries?id=${id}`, payload).then(res => {
      history.push("/admin/timeEntries")
    });
    loadPortfolio();
    history.push("timeEntries");
  };

  const loadPortfolio =  () => {
  isLoading(true)
    get(`timeEntries/gettimeEntrie?id=${id}`)
      .then((res) => {
        var data = res.data.data.Item
        var fromTimeSplit = data.from_time.split(' ');
        
        var ispm = fromTimeSplit[1] == 'PM' ? true : false; 
        console.log("ispm", fromTimeSplit, ispm);
       
        // if (time.includes("PM") && hours !== 12) hours += 12;
        
        fromTimeSplit = fromTimeSplit[0].split(':');
        var timeHours = parseInt(fromTimeSplit[0]);
        //timeHours += ( (ispm && (timeHours != 12)) ? 12 : 0)
        timeHours += ((ispm || timeHours == 12 ) ? 12 : 0);
        var timeMinuts = parseInt(fromTimeSplit[1]); 

        // for to_time
        var toTimeSplit = data.to_time.split(' ');
        
        var toIspm = toTimeSplit[1] == 'PM' ? true : false; 
        console.log("ispm", toTimeSplit, toIspm);
       
        // if (time.includes("PM") && hours !== 12) hours += 12;

        toTimeSplit = toTimeSplit[0].split(':');
        var toTimeHours = parseInt(toTimeSplit[0]);
        //timeHours += ( (ispm && (timeHours != 12)) ? 12 : 0)
        toTimeHours += ((toIspm || toTimeHours == 12 ) ? 12 : 0);
        var toTimeMinuts = parseInt(toTimeSplit[1]); 
        

        console.log("oops", data.to_time)
        console.log(' timeHours ', toTimeHours);
      isLoading(false)
      setTimeEntries(data);
      setSelectClient({label:data.client_names, value:data.client_names})
      setChecked(data.taxable)
      setStartDate(new Date(data.timeEntries_date))
      // setFromTime(new Date(data.from_time).setTime("1645769471704"))
      // setFromTime(new Date(data.timeEntries_date).setHours(timeHours,timeMinuts,1))
      setFromTime(new Date(data.timeEntries_date).setHours(timeHours,timeMinuts,1))
      setToTime(new Date(data.timeEntries_date).setHours(toTimeHours,toTimeMinuts,1))
      // setFromTime(new Date().getTime())
    })
    .catch(() => {});
  };
  return loading ? (
    "loading..."
  ) : (
    <div className="container">
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Edit A Time Entries</h2>
        <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
            {/* <select
              name="client_names"
              value={selectClient}
              className="browser-default custom-select custom-select-lg" 
              onChange={e => setSelectClient(e.target.value)}
            >
              <option value="">Select Client Name</option>
              {client.map(client => (
                <option value={client?.client_names}>{client?.client_names}</option>
              ))}
            </select> */}
            <Select
            // defaultValue={selectClient}
            value={selectClient}
            onChange={setSelectClient}
            options={client}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your Job Description"
              name="job_description"
              value={job_description}
              onChange={e => onInputChange(e)}
            />
          </div>
          <div className="form-group">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className="form-group">
          <DatePicker
            selected={fromTime}
            onChange={(date) => setFromTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="hh:mm aa"
          />
          </div>
          <div className="form-group">
          <DatePicker
            selected={toTime}
            onChange={(date) => setToTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="hh:mm aa"
          />
          </div>
            <input type="checkbox" name="taxable" value={taxable} onChange={handleChange} checked={ checked}/>
            
          <button className="btn btn-warning btn-block" type="submit">
            Update Time Entries
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditTimeEntries;
