import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {

    const isAuthenticated = localStorage.getItem("Token");
    console.log(isAuthenticated, "auth token");  
    return (
      <Route
        {...restOfProps}
        render={(props) =>
            isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    );
  }

export default ProtectedRoute;