import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import get from "../../helper/api"

const ViewPartner = () => {
  const [timeEntries, setTimeEntries] = useState({
    client_names: "",
    job_description: "",
    timeEntries_date: "",
    from_time: "",
    to_time: "",
    taxable: ""
  });
  const { id } = useParams();
  useEffect(() => {
    loadPartner();
  }, []);
  const loadPartner = async () => {
 
    get(`timeEntries/gettimeEntrie?id=${id}`)
    .then((res) => {
      var data = res.data?.data.Item
      console.log(data);
      setTimeEntries(data);
    })
    .catch(() => {});

  };
  return (
    <div className="container py-4">
      <Link className="btn btn-primary" to="/admin/timeEntries">
        back to Home
      </Link>
      <h1 className="display-4">Time Entries Id: {id}</h1>
      <hr />
      <ul className="list-group w-50">
        <li className="list-group-item">Client Name: {timeEntries.client_names}</li>
        <li className="list-group-item">Job Description: {timeEntries.job_description}</li>
        <li className="list-group-item">Date: {timeEntries.timeEntries_date}</li>
        <li className="list-group-item">From Time: {timeEntries.from_time}</li>
        <li className="list-group-item">To Time: {timeEntries.to_time}</li>
        <li className="list-group-item">Taxable: {timeEntries.taxable? "Taxable": "Non-Taxable"}</li>
      </ul>
    </div>
  );
};

export default ViewPartner;
