import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import get from "../../helper/api"

const ViewClient = () => {
  const [client, setClient] = useState({
    client_names: "",
    rate: "",
    tax_name:""
 
  });
  const { id } = useParams();
  useEffect(() => {
    loadClient();
  }, []);
  const loadClient = async () => {
 
    get(`client/getClient?id=${id}`)
    .then((res) => {
      var data = res.data.data.Item
      console.log("specific",res.data.data)
      // alert(res.data.data)
      setClient(data);
    })
    .catch(() => {});

  };
  return (
    <div className="container py-4">
      <Link className="btn btn-primary" to="/admin/clients">
        back to Home
      </Link>
      <h1 className="display-4">Client Id: {id}</h1>
      <hr />
      <ul className="list-group w-50">
        <li className="list-group-item">Client Name: {client.client_names}</li>
        <li className="list-group-item">Rate: {client.rate}</li>
        
        <li className="list-group-item">Tax Name: {client.tax_name}</li>
              </ul>
    </div>
  );
};

export default ViewClient;
