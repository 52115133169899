import React, { useState, useEffect } from "react";
import {  useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import get,{put} from "../../helper/api"

const EditClient = () => {
  let history = useHistory();
  const { id } = useParams();
  const [client, setClient] = useState({
    client_names: "",
    rate: "",
    tax_name:""
  });
const [loading,isLoading]=useState(false)
  
  const onInputChange = e => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadClient()
  }, [])
  const onSubmit = async e => {
    e.preventDefault();
    let payload = {
      client_names: client.client_names,
      rate: client.rate,
      tax_name: client.tax_name
    }
    put(`client/updateClient?id=${id}`, payload).then(res => {
      history.push("/admin/clients")
    });
    loadClient()
    history.push("clients");
  };

  const loadClient = async () => {
    isLoading(true)
      get(`client/getClient?id=${id}`)
      .then((res) => {
        var data = res.data.data.Item
        isLoading(false)
        setClient(data);
      })
      .catch(() => {});
    };

  const { client_names, rate, tax_name} = client;
  return loading ? (
    "loading..."
  ) : (
    <div className="container">
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Edit A Client</h2>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your Name"
              name="client_names"
              value={client_names}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your Username"
              name="rate"
              value={rate}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Link"
              name="tax_name"
              value={tax_name}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <button className="btn btn-warning btn-block">Update Client</button>
        </form>
      </div>
    </div>
  );
};

export default EditClient;
