import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { post } from 'helper/api';
import { setAuthorizationToken } from 'helper/setAuthorizationToken';
// import { ValidationForm, TextInput } from "react-bootstrap4-form-validation"
import { toast } from 'react-toastify';
import LoaderCard from "../components/SharedUI/Loader"
import Validator from "validatorjs";
import FormValidationError from './SharedUI/FormValidationError';

const Login = () => {

  let history = useHistory();
  const [loading, isLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const { email, password} = login;
  const onInputChange = e => {
    setLogin({ ...login, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      setEmailError("");
    } else if (e.target.name === "password") {
      setPasswordError("");
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      email: login.email,
      password: login.password,
    };
    let rules = {
      email: "required",
      password: "required",
    };

    let validation = new Validator(data, rules);

    validation.passes(); // true
    validation.fails(); // false
    if (validation.passes()) {
      let payload = {
        email: data.email,
        password: data.password,
      };
      isLoading(true)
    await post("auth/login", payload)
    .then((res) => {
      var data = res.data
      if (data.success === true) {
        isLoading(false)
        toast.success(data.response)
        setLogin(data);
        setAuthorizationToken(data.accessToken)
        history.push("/admin");
      }
      else {
        isLoading(false)
        toast.success(data.response)
      }
    })
    } else if (validation.fails()) {
      if (validation.errors.has("email")) {
        setEmailError("Please enter user email");
      }

      if (validation.errors.has("password")) {
        setPasswordError("Please enter password");
      }
    }
      }
  return loading ? (
      <LoaderCard/> ) : (
        <section class="h-100 gradient-form" style={{ "backgroundColor": "#eee"}}>
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <div class="col-lg-6">
              <div class="card-body p-md-5 mx-md-4">

                <div class="text-center">
                    <img src="https://mdbootstrap.com/img/Photos/new-templates/bootstrap-login-form/lotus.png" style={{"width": "185px"}} alt="logo"/>
                  <h4 class="mt-1 mb-5 pb-1">We are The Lotus Team</h4>
                </div>

                <form onSubmit={e => onSubmit(e)}>
                  <p>Please login to your account</p>

                  <div class="form-outline mb-4">
                    <input 
                      name="email"
                      value={email}
                      onChange={e => onInputChange(e)}
                      type="email" id="form2Example11"
                      class="form-control"
                      placeholder="Please enter your email"
                      // required
                      // successMessage=""
                      // autoComplete="off"
                      // errorMessage="Please enter your email"
                    />
                    {emailError && (
                      <FormValidationError errorMessage={emailError} />
                    )}
                  </div>

                  <div class="form-outline mb-4">
                    <input
                      name="password"
                      value={password}
                      onChange={e => onInputChange(e)}
                      type={showPassword ? 'text': 'password'}
                      placeholder="Please enter password"
                      id="form2Example22"
                      // pattern="(?=.*[a-z]).{6,}"
                      // required
                      // successMessage=""
                      // autoComplete="off"
                      // errorMessage={{required:"Password is required", pattern: "Password should be at least 6 characters"}}
                      class="form-control"
                    />
                    {passwordError && (
                      <FormValidationError errorMessage={passwordError} />
                    )}
                  </div>
                  
                  <input onClick={handleShowPassword} type="checkbox" id="showPassword"/>
                    <label for="showPassword" className="pl-2">Show password</label>

                  <div class="text-center pt-1 mb-5 pb-1">
                    <button class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="submit">Log in</button>
                  </div>
                </form>

              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                <h4 class="mb-4">We are more than just a restaurant</h4>
                <p class="small mb-0">A restaurant (sometimes known as a diner) is a place where cooked food is sold to the public, and where people sit down to eat it. It is also a place where people go to enjoy the time and to eat a meal. Some restaurants are a chain, meaning that there are restaurants which have the same name and serve the same food.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    )
}

export default Login
