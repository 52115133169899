import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import get, { del } from "../../helper/api";
import "./projects.css";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import View from "@material-ui/icons/Visibility"
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const useStyles = makeStyles(styles);
const Supplies = () => {
  const [portfolios, setPortfolios] = useState([]);

  const classes = useStyles();
  useEffect(() => {
    loadPortfolio();
  }, []);

  const dialogBox = (id) => {
    confirmAlert({
      message: 'Are you sure you want to delete this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deletePortfolio(id)
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const deletePortfolio = (id) => {
    del(`supplies/deleteSupplies?id=${id}`)
      .then((res) => {
        loadPortfolio();
      })
      .catch((error) => {});
  };

  const searchClient = (key) => {
    get(`supplies/searchClients?client_names=${key}`)
    .then((res) => {
      setPortfolios(res.data)
      console.log("specific",res.data)
    })
    .catch(() => {});
  }

  const loadPortfolio = async () => {
    try {
      const res = await get("supplies/getSupplies")
      console.log("responsive", res.data.data.Items);
      setPortfolios(res.data.data.Items);
    } catch (err) {
      console.log(err);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 500)
    }
  }

  const handleChange = (event) => {
    searchClient(event.target.value)
  }

  // useCallback provide us the memoized callback
  const optimisedVersion = useCallback(debounce(handleChange), [])

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="py-4">
          <h1>Supplies Page</h1>
          <input type="search" placeholder="Search" onChange={optimisedVersion} autoComplete="off"/>
          <br /><br />
          <table className="table border shadow">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Client Name</th>
                <th scope="col">Description</th>
                <th scope="col">Date</th>
                <th scope="col">Price</th>
                <th scope="col">Taxable</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {portfolios?.map((portfolio, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{portfolio.client_names}</td>
                  <td>{portfolio.description}</td>
                  <td>{portfolio.supplies_date}</td>
                  <td>{portfolio.price}</td>
                  <td>{portfolio.taxable?"Taxable":"Non-Taxable"}</td>

                  <td>
                    
                    <Tooltip
                      id="tooltip-top"
                      title="View"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Link to={`/admin/view-supplies/${portfolio.id}`}>
                        <IconButton
                          aria-label="View"
                          className={classes.tableActionButton}
                        >
                          <View
                            className={
                              classes.tableActionButtonIcon + " " + classes.view
                            }
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top"
                      title="Edit"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Link to={`/admin/edit-supplies/${portfolio.id}`}>
                        <IconButton
                          aria-label="Edit"
                          className={classes.tableActionButton}
                        >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + " " + classes.edit
                            }
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top-start"
                      title="Remove"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Link to="supplies" onClick={() => dialogBox(portfolio.id)}>
                        <IconButton
                          aria-label="Close"
                          className={classes.tableActionButton}
                        >
                          <Close
                            className={
                              classes.tableActionButtonIcon +
                              " " +
                              classes.close
                            }
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Supplies;
