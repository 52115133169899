import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import { convertDateFormat, convertTimeFormat } from "../../components/helper/DateConversion";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import get, { post } from "../../helper/api"
import Select from "react-select"

export default function AddTimeEntries () {
  let history = useHistory();
  const [checked, setChecked] = useState(false); 
  const [startDate, setStartDate] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [fromTime, setFromTime] = useState(new Date());
  console.log("from-time", fromTime);
  const [client, setClient] = useState([]);
  const [selectClient, setSelectClient] = useState();
  const [timeEntries, setTimeEntries] = useState({
    client_names: "",
    job_description: "",
    timeEntries_date: "",
    from_time: "",
    to_time: "",
    taxable: "",
  });

  const { client_names, job_description, timeEntries_date, from_time, to_time, taxable} = timeEntries;
  const onInputChange = e => {
    setTimeEntries({ ...timeEntries, [e.target.name]: e.target.value });
  };

  const handleChange = () => { 
    setChecked(!checked); 
  }; 
 
  useEffect(() => {
    clients();
  }, []);

  const clients = async () => {
    const res = await get("client/getClientDropdown")
    const data = res.data.Items
    const options = data.map(d => ({
      value : d.id,
      label : d.client_names
    }))
    setClient(options)
 };
    
  const onSubmit = async e => {
    e.preventDefault()
    try {
      let payload = {
        client_names: selectClient.label,
        job_description: timeEntries.job_description,
        timeEntries_date: convertDateFormat(startDate),
        from_time: convertTimeFormat(fromTime),
        to_time: convertTimeFormat(toTime),
        taxable: checked,
      }
      const res = await post("timeEntries/createtimeEntries", payload)
      setTimeEntries(res.data);
      history.push("/admin/timeEntries");
    } catch (err) {
      console.log(err);
      history.push("/admin/timeEntries");
    }
  }
  
  return (
    <div className="container">
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Add A Time Entries</h2>
        <form onSubmit={e => onSubmit(e)}>
        <div className="form-group">
            {/* <select
              name="client_names"
              className="browser-default custom-select custom-select-lg" 
              onChange={e => setSelectClient(e.target.value)}
            >
              <option value="">Select Client Name</option>
              {client.map(client => (
                <option value={client?.client_names}>{client?.client_names}</option>
              ))}
            </select> */}
            <Select
              defaultValue={selectClient}
              onChange={setSelectClient}
              options={client}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Enter Your Job Description"
              name="job_description"
              value={job_description}
              onChange={e => onInputChange(e)}
            />
          </div>
          <div className="form-group">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div className="form-group">
          <DatePicker
            selected={fromTime}
            onChange={(date) => setFromTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="hh:mm aa"
          />
          </div>
          <div className="form-group">
          <DatePicker
            selected={toTime}
            onChange={(date) => setToTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="hh:mm aa"
          />
          </div>
          <input type="checkbox" name="taxable" value={taxable} onChange={ handleChange}/>

          <button type='submit' className="btn btn-primary btn-block">Add Time Entries</button>
        </form>
      </div>
    </div>
  );
}