import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import get from "../../helper/api"

const ViewSupplies = (props) => {
  const [supplies, setSupplies] = useState({
    client_names: "",
    description: "",
    supplies_date: "",
    price: "",
    taxable: ""
  });
  const { id } = useParams();
  useEffect(() => {
    loadUser();
  }, []);
  const loadUser = async () => {
 
    get(`supplies/getSupplie?id=${id}`)
    .then((res) => {
      var data = res.data.data.Item
      // alert(res.data.data)
      setSupplies(data);
    })
    .catch(() => {});

  };

  return (
    
    <div className="container py-4">
      <Link className="btn btn-primary" to="/admin/supplies">
        back to Home
      </Link>
      <h1 className="display-4">Project Id: {id}</h1>
      <hr />
      <ul className="list-group w-50">
        <li className="list-group-item">Client Name: {supplies.client_names}</li>
        <li className="list-group-item">Description: {supplies.description}</li>
        <li className="list-group-item">Date: {supplies.supplies_date}</li>
        <li className="list-group-item">Price: {supplies.price}</li>
        <li className="list-group-item">Taxable: {supplies.taxable? "Taxable": "Non-Taxble"}</li>
      </ul>
    </div>
  );
};

export default ViewSupplies;
